import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getIsPreviewStatus } from '../../state/app';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import { EffectCoverflow, Navigation, Pagination, A11y } from 'swiper';
import { ContentfulNavigationLink } from './ContentfulNavigationLink';

const SectionHamboneNomineesSliderScale = (section) => {
     const changeAndAddTab = (swiper, event) => {
          if (event !== 'handleChange') {
               document.querySelector('.swiper').insertBefore(document.querySelector('.hambone-nominees-slider-wrapper .swiper-prev'), document.querySelector('.swiper-wrapper'));
          }

          const swiperSlide = document.querySelectorAll('.swiper-slide');

          document.querySelectorAll('.swiper-slide a').forEach((elementChild) => {
               elementChild.removeAttribute('tabindex');
          });

          swiperSlide.forEach((element, index) => {
               if (index !== swiper.activeIndex) {
                    element.querySelectorAll('a').forEach((elementChild) => {
                         elementChild.setAttribute('tabindex', -1);
                    });
               }
          });
     };

     return (
          <section className="hambone-nominees-slider">
               <div className="hambone-nominees-slider-title">
                    <h2>{section.nomineesSliderTitle}</h2>
               </div>
               <div className="hambone-nominees-slider-wrapper">
                    <Swiper
                         effect={'coverflow'}
                         slidesPerView="auto"
                         spaceBetween={105}
                         centeredSlides={true}
                         loop={true}
                         coverflowEffect={{
                              scale: 0.88,
                              rotate: 0,
                              slideShadows: false
                         }}
                         modules={[EffectCoverflow, Navigation, A11y]}
                         navigation={{ prevEl: '.swiper-prev', nextEl: '.swiper-next' }}
                         breakpoints={{
                              320: {
                                   spaceBetween: 10
                              },
                              768: {
                                   spaceBetween: 105
                              }
                         }}
                         onSwiper={(swiper) => {
                              changeAndAddTab(swiper);
                         }}
                         onSlideChange={(swiper) => {
                              changeAndAddTab(swiper, 'handleChange');
                         }}
                    >
                         <img loading="lazy" src="/images/icons/arrow-slides.svg" alt="Arrow Slides Prev" className="swiper-prev" />
                         {section.nomineePet?.map((nominee) => {
                              const nomineeImage = nominee.nomineesPetFeaturedImage?.file?.url || nominee.nomineesPetImage?.file?.url;
                              const nomineeSlug = `/hamboneaward/nominees/${nominee.nomineesOfTheYear?.nomineesYear}/${nominee.slug}/`;
                              return (
                                   <SwiperSlide key={nominee.id}>
                                        <div className="hambone-nominees-slider-card">
                                             <a
                                                  aria-label={nominee.nomineesPetName}
                                                  aria-labelledby={nominee.nomineesPetName}
                                                  href={nomineeSlug}
                                                  className="hambone-nominees-slider-card-image"
                                                  style={{ backgroundImage: `url(${nomineeImage})` }}
                                             ></a>
                                             <div className="hambone-nominees-slider-card-content">
                                                  <div className="hambone-nominees-slider-card-content-box">
                                                       <h3>
                                                            <a href={nomineeSlug}>{nominee.nomineesPetName}</a>
                                                       </h3>
                                                       <p>{nominee.nomineesPetShortText}</p>
                                                       <span>{nominee.voteOnceADayText}</span>
                                                       <a href={nomineeSlug} target="_self" className="hambone-nominees-slider-card-content-read">
                                                            Read more
                                                       </a>
                                                  </div>
                                             </div>
                                        </div>
                                   </SwiperSlide>
                              );
                         })}
                         <img loading="lazy" src="/images/icons/arrow-slides.svg" alt="Arrow Slides Next" className="swiper-next" />
                         <div className="hambone-nominees-slider-link">
                              <img loading="lazy" src="/images/icons/arrow-slides.svg" alt="Arrow Slides Prev" className="swiper-prev" />
                              <ContentfulNavigationLink {...section.allNomineesLink} />
                              <img loading="lazy" src="/images/icons/arrow-slides.svg" alt="Arrow Slides Next" className="swiper-next" />
                         </div>
                    </Swiper>
               </div>
          </section>
     );
};

const SectionWackyPetStorySlider = (section) => {
     const { isPreview } = section;

     const changeAndAddTab = (swiper) => {

          document.querySelector('.hambone-nominees-slider-link a').setAttribute('tabindex', -1);
          document.querySelector('.hambone-nominees-slider-link .swiper-prev').setAttribute('tabindex', -1);
          document.querySelector('.hambone-nominees-slider-link .swiper-next').setAttribute('tabindex', -1);
          const swiperSlide = document.querySelectorAll('.swiper-slide');

          document.querySelectorAll('.swiper-slide a').forEach((elementChild) => {
               elementChild.removeAttribute('tabindex');
          });

          swiperSlide.forEach((element, index) => {
               if (index !== swiper.activeIndex) {
                    element.querySelectorAll('a').forEach((elementChild) => {
                         elementChild.setAttribute('tabindex', -1);
                    });
               }
          });
     };

     return (
          <section className="hambone-nominees-slider wacky-nominees-slider">
               <div className="hambone-nominees-slider-title">
                    <h2>{section.nomineesSliderTitle}</h2>
               </div>

               <div className="hambone-nominees-slider-wrapper">
                    <Swiper
                         effect={'coverflow'}
                         slidesPerView="auto"
                         spaceBetween={20}
                         centeredSlides={true}
                         className="mySwiper"
                         loop={true}
                         pagination={{
                              clickable: true
                         }}
                         // navigation= {true}
                         // navigation={navigation}
                         modules={[Navigation, Pagination, A11y]}
                         navigation={{ prevEl: '.swiper-prev', nextEl: '.swiper-next' }}
                         breakpoints={{
                              320: {
                                   spaceBetween: 10
                              },
                              768: {
                                   spaceBetween: 20
                              }
                         }}
                         onSwiper={changeAndAddTab}
                         onSlideChange={changeAndAddTab}
                    >
                         {section.wackyPetStory?.map((wacky, index) => {
                              const wackyImage = isPreview ? wacky.fields.image.fields.file.url : wacky.image?.file.url;
                              const wackySlug = isPreview
                                   ? `/wackypetnames/${wacky.fields.category.fields.slug}/${wacky.fields.slug}`
                                   : `/wackypetnames/${wacky.category?.slug}/${wacky.slug}`;
                              const wackyPetName = isPreview ? wacky.fields.petName : wacky.petName;
                              const wackyShortDescription = isPreview ? wacky.fields.shortDescription : wacky.shortDescription;
                              return (
                                   <SwiperSlide key={index}>
                                        <div className="hambone-nominees-slider-card">
                                             <a
                                                  aria-label={wackyPetName}
                                                  aria-labelledby={wackyPetName}
                                                  href={wackySlug}
                                                  className="hambone-nominees-slider-card-image"
                                                  style={{ backgroundImage: `url(${wackyImage})` }}
                                             ></a>
                                             <div className="hambone-nominees-slider-card-content">
                                                  <div className="hambone-nominees-slider-card-content-box">
                                                       <h3>
                                                            <a href={wackySlug}>{wackyPetName}</a>
                                                       </h3>
                                                       <p>{wackyShortDescription}</p>
                                                       <div className="button-container">
                                                            <a href={wackySlug}>Read their story</a>
                                                       </div>
                                                  </div>
                                             </div>
                                             {/* <img src="/images/icons/arrow-slides.svg" alt="Arrow Slides Prev" className="swiper-prev" />
                                                  <img src="/images/icons/arrow-slides.svg" alt="Arrow Slides Next" className="swiper-next" /> */}
                                        </div>
                                   </SwiperSlide>
                              );
                         })}
                    </Swiper>
               </div>
               <div className="hambone-nominees-slider-link">
                    <img loading="lazy" src="/images/icons/arrow-slides.svg" alt="Arrow Slides Prev" className="swiper-prev" />
                    <ContentfulNavigationLink {...section.allNomineesLink} />
                    <img loading="lazy" src="/images/icons/arrow-slides.svg" alt="Arrow Slides Next" className="swiper-next" />
               </div>
          </section>
     );
};

const SectionHamboneNomineesSlider = (section) => {
     const getComponent = () => {
          switch (section.layoutTemplate) {
               case 'Hambone Nominees Slider':
                    return <SectionHamboneNomineesSliderScale {...section} />;
               case 'Wacky Pet Story Slider':
                    return <SectionWackyPetStorySlider {...section} />;
               default:
                    return <SectionHamboneNomineesSliderScale {...section} />;
          }
     };
     return <>{getComponent()}</>;
};

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

SectionHamboneNomineesSlider.propTypes = {
     isPreview: PropTypes.bool
};

const ContentfulSectionHamboneNomineesSlider = connect(mapStateToProps)(SectionHamboneNomineesSlider);

export default ContentfulSectionHamboneNomineesSlider;
